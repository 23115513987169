<template>
    <div>
        <v-data-table
            fixed-header
            :items="dados"
            :headers="headers"
            :loading="loading"
            :mobileBreakpoint="0"
            :height="300"
            :disable-pagination="true"
            :hide-default-footer="true"
            @click:row="openComanda"
            :options.sync="options"
        >
            <template v-slot:item.id="{item}">
                <span style="color: blue; text-decoration: underline; cursor: pointer;">
                    {{ item.id }}
                </span>
            </template>
            <template v-slot:item.created_at="{item}">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.total="{item}">
                <span style="color: green;">
                    R$&nbsp;{{ formatPrice(+item.total) }}
                </span>
            </template>
            <template v-slot:item.valor_frete="{item}">
                <span v-if="+item.valor_frete" style="color: green;">
                    R$&nbsp;{{ formatPrice(+item.valor_frete) }}
                </span>
            </template>
            <template v-slot:item.status="{item}">
                <v-chip
                    class="white--text"
                    :color="item.status == 'cancelado' ? 'red' : 'green'"
                >
                    {{ item.status }}
                </v-chip>
            </template>
            <template v-slot:item.action="{item}">
               <v-btn icon @click.stop="repetir(item)">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <Paginate
            v-model="pagination"
            @input="consultar"
        />

        <DialogComanda
            v-model="comanda"
            :visible="dialogComanda"
            @close="dialogComanda = false"
        />
    </div>
</template>

<script>
import Paginate from '@/components/Paginate.vue';

export default {
    name: 'HistoricoPedidos',

    components: {
        DialogComanda: () => import('@/pages/home/DialogComanda.vue'),
        Paginate,
    },

    props: ['clienteId'],

    data: () => ({
        comanda: {},
        dialogComanda: false,
        loading: false,
        dados: [],
        headers: [
            { text: 'Nº', value: 'id' },
            { text: 'Data/Hora', value: 'created_at',  align: 'center' },
            { text: 'R$ Total', value: 'total' },
            { text: 'Forma Pgto', value: 'forma_pagamento' },
            { text: 'Forma Entrega', value: 'forma_entrega' },
            { text: 'Taxa Entrega', value: 'valor_frete' },
            { text: 'Status', value: 'status' },
            { text: 'Repetir Pedido', value: 'action', sortable: false, align: 'end'},
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
            ammount: 0,
        },
        options: {},
    }),

    watch: {
        clienteId() {
            this.consultar();
        },

        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.dados = [];

            if (!this.clienteId) {
                return;
            }

            const params = this.getParams();

            this.loading = true;
            this.$http.get(`pedidos/cliente/${this.clienteId}?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },


        getParams() {
            const params = new URLSearchParams();

            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        async openComanda(comanda) {
            this.$root.$emit('loading', true);
            this.$http.get(`pedidos/${comanda.id}`)
                .then(resp => {
                    this.comanda = resp.data;
                    this.dialogComanda = true;
                })
                .finally(() => this.$root.$emit('loading', false));
        },

        async repetir(comanda) {
            this.$root.$emit('loading', true);
            this.$http.get(`pedidos/${comanda.id}`)
                .then(resp => {
                    const newOrder = {
                        id: 'auto',
                        pronto: false,
                        impresso: false,
                        finalizado: false,
                        status: 'confirmado',
                        status_historico: [],
                        nota_id: null,
                    };
                    const comanda = { ...resp.data, ...newOrder };

                    this.comanda = comanda;
                    this.dialogComanda = true;
                })
                .finally(() => this.$root.$emit('loading', false));
        },
    },
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
